function formatDate(dateString) {
  const dateObj = new Date(dateString);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[dateObj.getMonth()];

  const desiredFormat = `${dateObj.getDate()}-${month}-${dateObj.getFullYear()}`;

  return desiredFormat;
}
export { formatDate };
